/*****************************************
* General Styles
*****************************************/

body {
    background-color: #f1f1f1;
}

.lh-1 {
    line-height: 1rem;
}

.navbar-glass {
    background-color: rgba(241,241,241, 0.96);
}

.bg-light {
    background-color: #f9f9f9 !important;
}

.gmap>div {
    border-radius: 0.375rem;
}

.circle-dashed {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.bd-placeholder-img {
    width: 80px;
    height: 80px;
    display: flex;
    font-size: 1.4em;
    align-items: center;
    background-color: #ccc;
}

.c-pointer {
    cursor: pointer;
}

.avatar-profile {
    top: 20px;
    right: 30px;
    transform: none;
}

.avatar.avatar-55xl {
    height: 5.5rem;
    width: 5.5rem;
}

.avatar img {
    object-position: center;
}

.card .card-body .avatar.avatar-profile {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-40%) translateX(-10%);
}

.background-image-profile {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-image-profile.profile-lg {
    width: 80px;
    height: 80px;
}

.rbt-input-main {
    border: none;
    padding: 0;
}

.rbt-input-main:focus {
    box-shadow: none;
}

.rbt-menu.dropdown-menu {
    padding: .25rem;
}

.tf-select>div {
    padding: 0 0.3rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #d8e2ef !important;
    border-radius: 0.25rem !important;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.tf-select.is-invalid>div {
    border-color: #e63757 !important;
}

.form-control {
    height: calc(1.5em + 0.625rem + 4px);
}

.custom-validation-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 75%;
    color: #e63757;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.z-index-0 {
    z-index: 0;
}

.center-object {
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.calendar-day {
    white-space: nowrap;
}

.overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #e63757;
    color: #fff;
    border-radius: 5px;
}

.background-image-profile {
    position: relative;
}

.tf-flex-wrap:hover .overlay {
    opacity: 0.8;
}

.background-image-profile .text {
    text-align: center;
    height: 80px;
    line-height: 80px;
}

.background-image-profile .h6 {
    color: #fff;
}

.flatpickr-wrapper {
    width: 100%;
}

.tf-flex-wrap {
    flex-flow: wrap;
}

.card-gradient {
    background: linear-gradient(45deg, rgb(77,199,245), rgb(0,155,222));
}

.card-gradient.archived {
    background: linear-gradient(45deg, rgb(245,77,115), rgb(222,0,44));
}

.tf-truncate-text {
    max-width: calc(100% - 30px);
    /* width: 90%; */
    /* display: block; */
    /* text-overflow: ellipsis; */
    /* word-wrap: initial; */
    /* white-space: pre-wrap; */
    /* overflow: hidden; */
}

.rbt-input-hint {
    top: 6px !important;
}

/*****************************************
* Tables
*****************************************/

.pagination.react-bootstrap-table-page-btns-ul {
    float: right;
}

.react-bootstrap-table tbody tr {
    cursor: pointer;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.table td, .table th {
    padding: 0.45rem;
    vertical-align: middle !important;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 0.9em;
}

.table th.sortable span {
    /* float: right; */
}

/*****************************************
* Buttons
*****************************************/


/*****************************************
* Spinners
*****************************************/

.spinner-grow-lg {
    width: 3em;
    height: 3em;
}


/*****************************************
* Toasts 
*****************************************/

.Toastify__toast {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 5px 5px 0 #999;
}

.Toastify__toast-container--bottom-right {
    right: 0;
}

.Toastify__toast-container {
    padding: 0;
    width: auto;
}

.Toastify__close-button {
    display: none;
}

/****************************************
* Media Cards
*****************************************/

/* .media .btn-group-vertical,
.media .btn {
    opacity: 0;
    transition: opacity 100ms linear;
}

.media:hover .btn-group-vertical,
.media:hover .btn {
    opacity: 1;
}

.media .text-ellipsis-250 {
    height: 1.5em;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

.media .media-body p:first-of-type {
    margin-bottom: 0;
}

/****************************************
* Cards
*****************************************/

.card-header.bodyless {
    border-radius: 0.375rem;
    border-bottom: none;
}


/* 
.card .btn-group, .card .btn-group-vertical, .media .btn-danger {
    opacity: 0;
    transition: opacity 100ms linear;
}

.card:hover .btn-group, .card:hover .btn-group-vertical, .media:hover .btn-danger {
    opacity: 1;
} */

.card .banner-image,
.card .banner-image img {
    padding: 0;
    border-radius: 0.375rem;
}

.card.map-card .card-body div div {
    border-radius: 0.375rem;
}

.card .banner-image .banner-image-modals {
    position: absolute;
    width: 100%;
    top: 10px;
    left: 10px;
    opacity: 0;
    transition: opacity 100ms linear;
}

.card .banner-image .banner-image-modals div {
    display: inline-block;
}

.card .card-body.banner-image:hover .banner-image-modals {
    opacity: .8;
}

.card .card-body.banner-image .details {
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    position: absolute;
    bottom: 30px;
    left: 30px;
    border-radius: 10px;
    padding: 10px;
    line-height: 1em;
    color: #fff;
}

.card .card-body.banner-image .details h4 {
    margin: 0;
    color: #fff;
}

.card .card-body .profile-upload-button,
.card .card-body.banner-image .profile-upload-button {
    position: absolute;
    bottom: 25px;
    left: 28px;
    opacity: 0;
    transition: opacity 100ms linear;
}

.card .card-body .avatar:hover .profile-upload-button,
.card .card-body.banner-image .avatar:hover .profile-upload-button {
    opacity: 1;
}

.card .addRow {
    font-weight: 600;
}

.card.collapsable .card-header:first-child {
    border-radius: calc(0.375rem - 0px);
}
.card-header { position: relative; }
.card-header .svg-inline--fa.fa-chevron-up {
    transition: .3s transform ease-in-out;
    position: absolute;
    right: 25px;
    top: calc(50% - 6px);
}

.card.collapsable .card-header .svg-inline--fa.fa-chevron-up {
    transform: rotate(180deg);
}

.hasError,
.tf-select.hasError > div {
    border-color: #e63757 !important;
}

.hasError::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red !important;
    opacity: 1; /* Firefox */
}

.hasError:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red !important;
}

.hasError::-ms-input-placeholder { /* Microsoft Edge */
    color: red !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #fff;
}

/****************************************
* Tinymce
*****************************************/

.tox.tox-tinymce {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    z-index: 0;
}


/****************************************
* ImagePicker
*****************************************/

.image_picker .responsive {
    margin: 0 !important;
}

.image_picker .responsive .thumbnail {
    width: 100px !important;
    height: 100px !important;
    margin: 10px !important;
}

.image_picker .selected .checked .icon {
    width: 30px;
    height: 30px;
}

.image_picker .responsive .selected {
    background-color: rgb(253, 89, 95);
    border-color: rgb(253, 89, 95);
}

.image_picker .responsive .thumbnail:hover {
    box-shadow: none;
    border-color: rgb(253, 89, 95);
}


/****************************************
* Tabs
*****************************************/

.nav-tabs {
    border: none;
}

.nav-tabs .nav-item {
    cursor: pointer;
    margin-right: 5px;
    font-weight: bold;
    display: flex;
}

.nav-tabs .nav-link:hover {
    background-color: rgba(253, 89, 95, 0.2);
    border-radius: 3.125rem;
}

.nav-pills .nav-link.active {
    border-radius: 3.125rem;
    border: none;
    box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    background-color: rgb(253, 89, 95);
}

.nav-pills .nav-link.active:hover {
    color: #fff;
}

.btn-link {
    cursor: pointer;
}

.navbar-light .navbar-nav .nav-link.active {
    color: rgb(253, 89, 95);
}

.badge.badge-danger {
    background-color: rgb(253, 89, 95);
}

.message-badge {
    float: right;
    font-weight: 500;
    margin-top: 2px;
}

/****************************************
* QUILL
*****************************************/
.quill {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background: #f1f1f1;

    border: none;
    border-bottom: 1px solid #ccc;
    /* background-color: #ffffff;
    border-top: 1px solid #efefef;
     */
}

.ql-container.ql-snow {
    background-color: #fff;
    border: none;
    height: calc(100% - 44px);
}

.form-control {
    border-color: #ccc;
}

.notes-body .card-body { padding: 0; }
.notes-body .quill {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.notes-body .quill .ql-toolbar {
    border-radius: 0;
}

/****************************************
* react-images
*****************************************/

.react-images__blanket,
.react-images__positioner {
    z-index: 9999 !important;
}

@media (max-width: 768px) {
    .nav-pills .nav-link {
        margin-top: 0.5rem;
        padding: 0.5rem 0.3rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 575px) {
    .h3, h3 {
        font-size: 1.1em;
    }

    .h4, h4 {
        font-size: 1rem;
    }
}

@media (max-width: 767px) {
    .h3, h3 {
        font-size: 1.3em;
    }

    .h4, h4 {
        font-size: 1.2rem;
    }
}

@media (max-width: 991px) {
    .h4, h4 {
        font-size: 1.2rem;
    }
}

@media (max-width: 1500px) {
    .btn {
        /* font-size: 0.8rem !important; */
        /* padding: 0.1875rem 0.65rem; */
    }

    h4,
    .h4 {
        font-size: 1.04rem;
    }

    h5,
    .h5 {
        font-size: 0.9rem;
    }

    label {
        font-size: 0.7rem;
    }

    .tf-flex-wrap .text-secondary {
        font-size: 0.8rem;
    }

    .card p {
        font-size: 0.8rem;
    }
}